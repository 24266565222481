<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>E-book</div></b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-warning"
                  v-b-modal.modal-sm
                  :to="{ name: `admin-kategori-paket-ebook` }"
                  class="btn-icon mr-1"
                >
                  <feather-icon icon="ListIcon" class="mr-25" />Kategori
                  E-book</b-button
                >
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>

              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="lg"
                :title="titleModal"
                no-close-on-backdrop
                no-close-on-esc
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Photo" label-for="Photo">
                          <b-form-file
                            id="Photo"
                            v-model="form.photo"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept="image/*"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group label="Kategori" label-for="kategori">
                          <b-form-select
                            v-model="form.category_id"
                            :options="katebookData"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Please select an option
                                --</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12">
                        <b-form-group
                          label="Nama E-book"
                          label-for="nama-paket"
                        >
                          <b-form-input
                            v-model="form.name"
                            placeholder="Nama E-book"
                            name="nama-paket"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group
                          label="Jumlah Halaman"
                          label-for="num_page"
                        >
                          <b-form-input
                            v-model="form.num_page"
                            name="num_page"
                            placeholder="Jumlah Halaman"
                            type="number"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group
                          label="Tahun Terbit"
                          label-for="publication_year"
                        >
                          <b-form-input
                            v-model="form.publication_year"
                            name="publication_year"
                            placeholder="Contoh : 1970"
                            type="number"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="2">
                        <b-form-group label="Gratis" label-for="gratis">
                          <b-form-group>
                            <b-form-checkbox
                              v-model="form.gratis"
                              switch
                              inline
                            />
                          </b-form-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Harga" label-for="harga">
                          <b-form-input
                            :readonly="form.gratis == true"
                            v-model="form.price"
                            name="price"
                            placeholder="Harga"
                            type="number"
                            @keyup="doFormatRupiah"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="12">
                        <b-form-group label="Deskripsi" label-for="description">
                          <quill-editor
                            name="description"
                            placeholder="Deskripsi"
                            v-model="form.description"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group
                          label="File Ebook (Format PDF)"
                          label-for="file"
                        >
                          <b-form-file
                            id="file"
                            v-model="form.file"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            accept=".pdf"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="ebookData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(file_ebook)="data">
                  <b-button></b-button>
                  {{ apiFile + JSON.stringify(data.item.file_ebook.file) }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <!-- <b-button title="Ubah" variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon icon="EditIcon" /></b-button>
                  <b-button title="Hapus" variant="outline-danger" class="btn-icon ml-1" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-link>
                      <b-dropdown-item
                        :to="{
                          name: 'admin-ebook-detail',
                          params: { id: row.item.id },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>Detail</span>
                      </b-dropdown-item>
                    </b-link>
                    <b-dropdown-item @click="ModalUbah(row.item)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="ModalHapus(row.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress
                    :value="data.value.value"
                    max="100"
                    :variant="data.value.variant"
                    striped
                  />
                </template>

                <template #cell(price)="data">
                  Rp.{{ formatRupiah(data.value) }}
                </template>
                <template #cell(is_free)="data">
                  <b-badge variant="info" v-if="data.value == 1">
                    Gratis
                  </b-badge>
                  <b-badge variant="success" v-else> Berbayar </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(name)="row">
                  <b-link
                    class="text-dark"
                    :to="{ path: `master-universitas/detail/${row.item.id}` }"
                    >{{ row.item.name }}</b-link
                  >
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BFormTextarea,
  BFormFile,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  VBTooltip,
  BLink,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BOverlay,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    VBTooltip,
    BLink,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      ebookData: [],
      katebookData: [],
      form: {
        id: null,
        name: null,
        description: null,
        price: 0,
        photo: null,
        num_page: null,
        publication_year: null,
        category_id: null,
        gratis: false,
        file: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "E-book", sortable: true },
        { key: "price", label: "Harga", sortable: true },
        { key: "is_free", label: "Gratis/Berbayar", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Paket" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {
    "form.gratis"(val) {
      if (val == true) {
        this.form.price = 0;
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        description: null,
        price: 0,
        photo: null,
        num_page: null,
        publication_year: null,
        category_id: null,
        gratis: false,
        file: null,
      };
    },
    doFormatRupiah() {
      this.form.price = this.formatRupiah(this.form.price);
    },
    tutupModal() {
      this.Modal = false;
      this.ModalUploadThumbnail = false;
      this.resetForm();
      this.getData();
    },
    ModalUpload(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "upload";
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah E-book";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      (this.form = {
        name: item.name,
        description: item.description,
        price: item.price,
        num_page: item.num_page,
        publication_year: item.publication_year,
        category_id: item.category.id,
        gratis: item.is_free == 1 ? true : false,
      }),
        (this.activeAction = "ubah");
      this.titleModal = "Ubah E-book";
      this.Modal = true;
      this.doFormatRupiah();
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("e-book/createUpdateDelete", data)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      const fd = new FormData();
      fd.append("name", this.form.name);
      let description = this.form.description || "";
      description = description.replaceAll("style=", "style2=");
      fd.append("description", description);
      fd.append("num_page", this.form.num_page);
      fd.append("publication_year", this.form.publication_year);
      fd.append("price", this.unFormatRupiah(this.form.price));
      fd.append("is_free", this.form.gratis ? 1 : 0);
      fd.append("category_id", this.form.category_id);
      if (this.id) {
        fd.append("id", this.id);
      }
      if (this.form.photo) {
        fd.append("photo", this.form.photo);
      }
      if (this.form.file) {
        fd.append("file_ebook", this.form.file);
      }

      this.loading = true;
      this.$bvModal.hide("modal-tambah");
      this.$store
        .dispatch("e-book/createUpdateDelete", fd)
        .then(() => {
          this.loading = false;
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          this.getData();
          this.displaySuccess({
            text: "E-book berhasil di" + this.activeAction,
          });

          this.form = {
            id: null,
            name: null,
            description: null,
            price: 0,
            photo: null,
            num_page: null,
            publication_year: null,
            category_id: null,
            gratis: false,
            file: null,
          };
        })
        .catch((e) => {
          this.loading = false;
          this.$bvModal.show("modal-tambah");
          this.displayError(e);
          return false;
        });
    },
    UploadThumbnail() {
      const fd = new FormData();
      fd.append("thumbnail", this.form.thumbnail);
      fd.append("name", this.form.name);
      fd.append("description", this.form.description);
      fd.append("num_page", this.form.num_page);
      fd.append("publication_year", this.form.publication_year);
      fd.append("price", this.form.price);
      fd.append("max_quota", this.form.max_quota);
      fd.append("category_id", this.form.category_id);
      if (this.id) {
        fd.append("id", this.form.id);
      }

      this.$store
        .dispatch("paketTryout/createUpdateDelete", fd)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.ModalUploadThumbnail = false;

          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Thumbnail berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const payload = {};
      this.$store
        .dispatch("e-book/index", payload)
        .then((response) => {
          this.loading = false;
          let ebookData = response.data.data;
          this.ebookData = ebookData;
          this.totalRows = this.ebookData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getDataKategoriEbook() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("e-book/indexKategoriEbook", payload)
        .then((response) => {
          let katebookData = response.data.data;
          katebookData.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.katebookData = katebookData;
          // this.totalRows = this.katebookData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
    this.getDataKategoriEbook();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
